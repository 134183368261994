var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"loading":_vm.loading,"items":_vm.computersToDisplay,"headers":_vm.headers,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('computer-role-icon',{attrs:{"value":item.role}})]}},{key:"item.parsedData.software.os",fn:function(ref){
var item = ref.item;
return [_c('os-icon',{attrs:{"os":item.parsedData.software.os}})]}},{key:"item.dynamicComputerData.lastCompatibleOs.full",fn:function(ref){
var item = ref.item;
return [(item.dynamicComputerData.lastCompatibleOs)?_c('span',[_vm._v(_vm._s(item.dynamicComputerData.lastCompatibleOs.full))]):_c('span',{staticClass:"caption"},[_vm._v("—")])]}},{key:"item.parsedData.hardware.mainStorage.available",fn:function(ref){
var item = ref.item;
return [_c('computer-storage-status',{attrs:{"computer":item}})]}},{key:"item.dynamicComputerData.needsSoftwareUpdate",fn:function(ref){
var item = ref.item;
return [(item.dynamicComputerData.needsSoftwareUpdate)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")]):_vm._e()]}},{key:"item.dynamicComputerData.obsolete",fn:function(ref){
var item = ref.item;
return [(item.dynamicComputerData.obsolete)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert")]):_vm._e()]}},{key:"item.parsedData.hardware.physicalMemory",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("humanReadableBytes")(item.parsedData.hardware.physicalMemory))+" ")]}},{key:"item.dynamicComputerData.modelName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"gray"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_vm._v(" "+_vm._s(item.dynamicComputerData.modelName)+" "+_vm._s(item.parsedData.hardware.cpuBrand)+" "+_vm._s(item.parsedData.hardware.cpuReference)+" "+_vm._s(item.parsedData.hardware.cpuCoreCount)+" coeurs — "+_vm._s(_vm._f("humanReadableBytes")(item.parsedData.hardware.physicalMemory))+" RAM "),_c('br'),_vm._v(" Données mises à jour "+_vm._s(_vm._f("moment")(item.lastPing,'from'))+", "+_vm._s(_vm._f("moment")(item.lastPing,'LLLL'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }