<template>
  <v-list-group :sub-group="isSubGroup" :value="openedByDefault">
    <template v-slot:activator>
      <v-list-item>
        <v-list-item-avatar size="sm" class="hidden-xs-only">
          <v-icon color="cyan lighten-3">mdi-office-building</v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="d-flex flex-row align-center">
          <y-branch-label :value="branch.uuid" :show-tooltip-only-on-error="true" :copy-on-click="false"/>
          <status-icons :value="branch"/>
          <span class="caption hidden-xs-only">&nbsp;— {{branch.computers.length}} ordinateur<span v-if="branch.computers.length>1">s</span></span>
          <v-spacer/>
          <span class="caption"><os-count-label :os-counter="branch.osCounter"/></span>
          <v-tooltip left v-if="displayLicenseHref">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small v-on="on" v-bind="attrs" @click.stop="openInNew"><v-icon x-small>mdi-open-in-new</v-icon></v-btn>
            </template>
            <span>Voir la licence de cette agence</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-list>
      <computer-list-item class="ml-sm-8 ml-md-16" v-for="(c,i) in branch.computers" :key="i" :computer="c"/>
    </v-list>
  </v-list-group>
</template>

<script>
import ComputerListItem from "@/components/ComputerListItem";
import OsCountLabel from "@/components/OsCountLabel";
import StatusIcons from "@/components/StatusIcons";
import {mapState} from "vuex";

export default {
  name: "BranchListItem",
  components: {StatusIcons, OsCountLabel, ComputerListItem},
  props: ['branch', 'company', 'isSubGroup', 'openedByDefault'],
  computed: {
    ...mapState('auth', ['userAuthorisations', 'userApps']),
    displayLicenseHref() {
      return this.licenseApp && this.userAuthorisations.filter(ua=>ua.authorization.role === 'root' || ua.authorization.role === 'support' || (ua.authorization.role === 'readonly' && !ua.scope.branchUuid && !ua.scope.companyUuid)).length > 0;
    },
    licenseApp() {
      if (!Array.isArray(this.userApps)) return null;
      let filter = this.userApps.filter(a=>a.identifier === 'yfx-license');
      return filter.length > 0 ? filter[0] : null;
    }
  },
  methods: {
    openInNew() {
      window.open(this.licenseApp.url+"/#/branches/"+this.branch.uuid+"?fromQ="+this.company.uuid, "_blank");
    }
  }
}
</script>

<style scoped>

</style>
