var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{staticClass:"hidden-xs-only"},[_c('computer-role-icon',{attrs:{"value":_vm.computer.role}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex flex-row align-center"},[_vm._v(" "+_vm._s(_vm._f("computerRoleLabel")(_vm.computer.role))+" "),_c('span',{staticClass:"caption"},[_vm._v("— "),_c('os-icon',{attrs:{"os":_vm.computer.parsedData.software.os}}),_vm._v(" "+_vm._s(_vm.computer.parsedData.name))],1),(_vm.computer.dynamicComputerData.obsolete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,1752239147)},[_vm._v(" Machine obsolète, cet ordinateur doit être remplacé par du matériel plus récent pour bénéficier des mises à jour de Yodaforex. ")]):_vm._e(),_c('span',{staticClass:"ml-2 caption"},[_vm._v("— "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("moment")(_vm.computer.lastPing,'from')))])]}}])},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.computer.lastPing,'LLLL')))])])],1)],1),_c('v-list-item-subtitle',[(_vm.computer.dynamicComputerData.modelName)?_c('span',[_vm._v(_vm._s(_vm.computer.dynamicComputerData.modelName)+" — ")]):_vm._e(),_vm._v(_vm._s(_vm.computer.parsedData.hardware.cpuBrand)+" "+_vm._s(_vm.computer.parsedData.hardware.cpuReference)+" "+_vm._s(_vm.computer.parsedData.hardware.cpuCoreCount)+" coeurs — "+_vm._s(_vm._f("humanReadableBytes")(_vm.computer.parsedData.hardware.physicalMemory))+" RAM ")]),_c('v-list-item-subtitle',[(_vm.computer.dynamicComputerData.needsSoftwareUpdate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-warning"},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("formatOS")(_vm.computer.parsedData.software.os))+" "+_vm._s(_vm._f("formatVersion")(_vm.computer.parsedData.software.osVersion)))])]}}],null,false,3604278965)},[_vm._v(" Une mise à jour du système d'exploitation est nécessaire ")]):_c('span',[_vm._v(_vm._s(_vm._f("formatOS")(_vm.computer.parsedData.software.os))+" "+_vm._s(_vm._f("formatVersion")(_vm.computer.parsedData.software.osVersion)))]),_c('span',{class:{'text-warning': _vm.computer.dynamicComputerData.needsSoftwareUpdate}}),(_vm.computer.dynamicComputerData.lastCompatibleOs)?_c('span',[_vm._v(" — version max de macOS : "+_vm._s(_vm.computer.dynamicComputerData.lastCompatibleOs.full))]):_vm._e()],1)],1),(_vm.computer.parsedData.hardware.mainStorage)?_c('v-list-item-action',[_c('computer-storage-status',{attrs:{"computer":_vm.computer}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }