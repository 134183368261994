<template>
  <div>
    <v-skeleton-loader type="list-item@4" v-if="loading"/>
    <v-list>
      <company-list-item v-for="(v,i) in companiesToDisplay" :company="v" :key="i"
                         :opened-by-default="companiesToDisplay.length === 1"/>
      <v-list-item v-if="companiesToDisplay.length === 0 && !loading">
        <v-list-item-subtitle>Aucun élément à afficher</v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import CompanyListItem from "@/components/CompanyListItem.vue";

export default {
  name: "ComputersAsTree",
  components: {CompanyListItem},
  props: {
    companies: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: 'all',
      validator: (value) => ['all', 'to-update', 'obsolete', 'storage-warning', 'storage-alert'].indexOf(value) !== -1,
    },
    branchToFilterOn: null,
    companyToFilterOn: null,
  },
  data: () => ({
  }),
  computed: {

    companiesToDisplay() {
      // Le code suivant peut être utile pour ré-intégrer le fonctionne de filtre multiples
      if (this.filter !== 'all' || this.companyToFilterOn || this.branchToFilterOn) {
        let companies = this.companies.filter(c => {
          let i = 0;
          if (this.filter !== 'all') i--;
          if (this.companyToFilterOn) i--;
          if (this.branchToFilterOn) i--;

          if (this.companyToFilterOn && c.uuid === this.companyToFilterOn.uuid) i++;
          if (this.branchToFilterOn && c.uuid === this.branchToFilterOn.companyUuid) i++;
          if (this.filter === 'to-update' && c.containsComputersToUpdate) i++;
          if (this.filter === 'obsolete' && c.containsObsoleteComputers) i++;
          if (this.filter === 'storage-warning' && c.containsComputersWithStorageWarning) i++;
          if (this.filter === 'storage-alert' && c.containsComputersWithStorageAlert) i++;
          return i === 0;
        });
        if (this.filter !== 'all' || this.branchToFilterOn) {
          return companies.map(c => {
            let newC = Object.assign({}, c);
            newC.branches = c.branches.filter(b => {
              let i = 0;
              if (this.filter !== 'all') i--;
              if (this.branchToFilterOn) i--;

              if (this.branchToFilterOn && b.uuid === this.branchToFilterOn.uuid) i++;
              if (this.filter === 'to-update' && b.containsComputersToUpdate) i++;
              if (this.filter === 'obsolete' && b.containsObsoleteComputers) i++;
              if (this.filter === 'storage-warning' && b.containsComputersWithStorageWarning) i++;
              if (this.filter === 'storage-alert' && b.containsComputersWithStorageAlert) i++;
              return i === 0;
            });
            return newC;
          })
        } else return companies;
      } else return this.companies;

    }
  }
}
</script>

<style scoped>

</style>
