<template>
  <v-data-table :loading="loading" style="width: 100%" :items="computersToDisplay" :headers="headers" :options.sync="options">


    <template v-slot:item.role="{item}">
      <computer-role-icon :value="item.role"/>
    </template>

    <template v-slot:item.parsedData.software.os="{item}">
      <os-icon :os="item.parsedData.software.os"/>
    </template>

    <template v-slot:item.dynamicComputerData.lastCompatibleOs.full="{item}">
      <span v-if="item.dynamicComputerData.lastCompatibleOs">{{ item.dynamicComputerData.lastCompatibleOs.full }}</span>
      <span v-else class="caption">—</span>
    </template>

    <template v-slot:item.parsedData.hardware.mainStorage.available="{item}">
      <computer-storage-status :computer="item"/>
    </template>

    <template v-slot:item.dynamicComputerData.needsSoftwareUpdate="{item}">
      <v-icon v-if="item.dynamicComputerData.needsSoftwareUpdate" color="warning">mdi-alert</v-icon>
    </template>

    <template v-slot:item.dynamicComputerData.obsolete="{item}">
      <v-icon v-if="item.dynamicComputerData.obsolete" color="error">mdi-alert</v-icon>
    </template>

    <template v-slot:item.parsedData.hardware.physicalMemory="{item}">
      {{ item.parsedData.hardware.physicalMemory | humanReadableBytes }}
    </template>

    <template v-slot:item.dynamicComputerData.modelName="{item}">
      <v-tooltip left>
        <template v-slot:activator="{on, attrs}">
          <v-icon v-on="on" v-bind="attrs" small color="gray">mdi-information-outline</v-icon>
        </template>
        {{ item.dynamicComputerData.modelName }}
        {{ item.parsedData.hardware.cpuBrand }} {{ item.parsedData.hardware.cpuReference }} {{ item.parsedData.hardware.cpuCoreCount }} coeurs — {{ item.parsedData.hardware.physicalMemory | humanReadableBytes }} RAM
        <br/>
        Données mises à jour {{ item.lastPing | moment('from') }}, {{ item.lastPing | moment('LLLL') }}
      </v-tooltip>
    </template>



  </v-data-table>
</template>

<script>
import ComputerRoleIcon from "@/components/ComputerRoleIcon.vue";
import ComputerStorageStatus from "@/components/ComputerStorageStatus.vue";
import OsIcon from "@/components/OsIcon.vue";
import convertBytesToHumanReadable from "human-readable-bytes";

export default {
  name: "ComputersAsGrid",
  components: {OsIcon, ComputerStorageStatus, ComputerRoleIcon},
  props: {
    computers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: 'all',
      validator: (value) => ['all', 'to-update', 'obsolete', 'storage-warning', 'storage-alert'].indexOf(value) !== -1,
    },
    branchToFilterOn: null,
    companyToFilterOn: null,
  },
  computed: {
    computersToDisplay() {
      if (this.filter !== 'all' || this.companyToFilterOn || this.branchToFilterOn) {
        return this.computers.filter(c => {
          let i = 0;
          if (this.filter !== 'all') i--;
          if (this.companyToFilterOn) i--;
          if (this.branchToFilterOn) i--;

          if (this.companyToFilterOn && c.companyUuid === this.companyToFilterOn.uuid) i++;
          if (this.branchToFilterOn && c.branchUuid === this.branchToFilterOn) i++;
          if (this.filter === 'to-update' && c.dynamicComputerData.needsSoftwareUpdate) i++;
          if (this.filter === 'obsolete' && c.dynamicComputerData.obsolete) i++;
          if (this.filter === 'storage-warning' && c.dynamicComputerData.storageStatus === 'WARNING') i++;
          if (this.filter === 'storage-alert' && c.dynamicComputerData.storageStatus === 'ALERT') i++;
          return i === 0;
        });
      } else {
        return this.computers;
      }
    }
  },
  filters: {
    humanReadableBytes(v, method = 1024) {
      return convertBytesToHumanReadable(v, method, 0, false)
    },
  },
  data: () => ({
    headers: [
      {value: 'company.name', text: "Société"},
      {value: 'branch.name', text: "Agence"},
      {value: 'role', text: "Rôle"},
      {value: 'parsedData.hardware.mainStorage.available', text: "Stockage"},
      {value: 'parsedData.hardware.cpuCoreCount', text: "Cores"},
      {value: 'parsedData.hardware.physicalMemory', text: "RAM"},
      {value: 'parsedData.software.os', text: "OS"},
      {value: 'parsedData.software.osVersion.full', text: "Version OS"},
      {value: 'dynamicComputerData.lastCompatibleOs.full', text: "Max. OS"},
      {value: 'dynamicComputerData.needsSoftwareUpdate', text: "MàJ OS"},
      {value: 'dynamicComputerData.obsolete', text: "Obsolète"},
      {value: 'dynamicComputerData.modelName', text: "", sortable: false},
    ],
    options: {multiSort: true, itemsPerPage: -1},
  })
}
</script>

<style scoped>

</style>
