<template>
      <span>
        <v-icon small v-if="os === 'MACOS'" style="transform: translateY(-2px)">mdi-apple</v-icon>
        <v-icon small v-else-if="os === 'WINDOWS'" style="transform: translateY(-1px)">mdi-microsoft-windows</v-icon>
        <v-icon small v-else style="transform: translateY(-2px)">mdi-file-question</v-icon>
      </span>
</template>

<script>
export default {
  name: "OsIcon",
  props: ['os']
}
</script>

<style scoped>

</style>
