<template>
  <v-container>
    <v-snackbar color="error" v-model="error">Une erreur est survenue</v-snackbar>
    <v-card :loading="loading">

      <v-card-text v-if="!accessKey && !loading">Pour consulter les données du parc informatique de votre société, veuillez afficher cette page depuis Yodaforex.</v-card-text>

      <v-skeleton-loader type="list-item@3" v-if="loading"/>
      <v-list v-if="!loading && !error">
        <div v-if="company != null && branchesToDisplay.length > 0">
          <branch-list-item :is-sub-group="false" :branch="b" v-for="(b,i) in branchesToDisplay" :key="i" :opened-by-default="branchesToDisplay.length === 1"/>
        </div>
        <v-list v-else>
          <v-list-item>
            <v-list-item-subtitle>Aucun élément à afficher</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-list>
    </v-card>

  </v-container>
</template>

<script>
import BranchListItem from "@/components/BranchListItem";
export default {
  name: "YodaforexView",
  components: {BranchListItem},
  data: () => ({
    loading: false,
    error: false,
    accessKey: null,
    company: null,
    branchToFilterOn: []
  }),
  mounted() {
    this.$http.defaults.headers.common['X-Api-Key'] = this.$sessionToken(); // On attache la clé d'API qui est dans le cookie (rajoutée par le serveur par YodaforexWebAreaAuthFilter.java)
    // On récupère la clé d'accès des cookies (aussi rajoutée par le serveur, voir ci-dessus)
    const value = `; ${document.cookie}`;
    const parts = value.split(`; Yfx-Access-Key=`);
    if (parts.length === 2) {
      this.accessKey = parts.pop().split(';').shift();
    }
    if (this.accessKey) {
      this.fetch();
    }
    if (typeof URLSearchParams === 'function') {
      let branches = new URLSearchParams(window.location.search).get('branches');
      if (branches) {
        this.branchToFilterOn = branches.split(',');
      }
    }
  },
  computed: {
    branchesToDisplay() {
      if (!this.company) return [];
      if (this.branchToFilterOn.length === 0) return this.company.branches;
      else return this.company.branches.filter(b => this.branchToFilterOn.indexOf(b.uuid) >= 0);
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$http.get(this.$config.apiUrl + "/v1/license-access/computers", {headers: {'X-Yodaforex-Access-Key': this.accessKey}})
          .then(r => this.company = r.data)
          .catch(() => this.error = true)
          .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>
