<template>
  <v-list-group :value="openedByDefault">
    <template v-slot:activator>
      <v-list-item>
        <v-list-item-avatar class="hidden-xs-only">
          <v-icon color="cyan darken-2">mdi-office-building-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="d-flex flex-row align-center">
          <y-company-label :value="company.uuid" :show-tooltip-only-on-error="true" :copy-on-click="false"/>
          <status-icons :value="company"/>
          <span class="caption hidden-xs-only">&nbsp;—&nbsp;{{company.branches.length}} agence<span v-if="company.branches.length>1">s</span></span>
          <v-spacer/>
          <span class="caption"><os-count-label :os-counter="company.osCounter"/></span>
          <v-tooltip left v-if="displayLicenseHref">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small v-on="on" v-bind="attrs" @click.stop="openInNew"><v-icon x-small>mdi-open-in-new</v-icon></v-btn>
            </template>
            <span>Voir les licences de cette société</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :loading="csv.loading" icon x-small v-on="on" v-bind="attrs" @click.stop="downloadCompanyCSV"><v-icon x-small>mdi-download</v-icon></v-btn>
            </template>
            <v-snackbar v-if="csv.error" :timeout="5" color="error" top>Une erreur est survenue lors du téléchargement du fichier CSV</v-snackbar>
            <span>Télécharger au format CSV</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>
    </template>

    <branch-list-item :is-sub-group="true" :branch="b" :company="company" v-for="(b,i) in company.branches" :key="i"/>
  </v-list-group>
</template>

<script>
import BranchListItem from "@/components/BranchListItem";
import OsCountLabel from "@/components/OsCountLabel";
import StatusIcons from "@/components/StatusIcons";
import {mapState} from "vuex";

export default {
  name: "CompanyListItem",
  components: {StatusIcons, OsCountLabel, BranchListItem},
  props: ['company', 'openedByDefault'],
  data: () => ({
    csv: {
      loading: false,
      error: false
    },
  }),
  computed: {
    ...mapState('auth', ['userAuthorisations', 'userApps']),
    displayLicenseHref() {
      return this.licenseApp && this.userAuthorisations.filter(ua=>ua.authorization.role === 'root' || ua.authorization.role === 'support' || (ua.authorization.role === 'readonly' && !ua.scope.branchUuid && !ua.scope.companyUuid)).length > 0;
    },
    licenseApp() {
      if (!Array.isArray(this.userApps)) return null;
      let filter = this.userApps.filter(a=>a.identifier === 'yfx-license');
      return filter.length > 0 ? filter[0] : null;
    }
  },
  methods: {
    openInNew() {
      window.open(this.licenseApp.url+"/#?q="+this.company.uuid, "_blank");
    },
    downloadCompanyCSV() {
      this.csv.loading = true;
      this.csv.error = false;
      this.$http({
        url: this.$config.apiUrl + "/v1/companies/" + this.company.uuid + "/computers?asCSV",
        method: 'GET',
        headers: {'Accept': 'text/csv'},
        responseType: 'blob', // important
      })
          .then((response) => {
            // Downloading the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report-computers-' + this.company.uuid + '.csv');
            document.body.appendChild(link);
            link.click();
          })
          .catch(() => this.csv.error = true)
          .finally(() => this.csv.loading = false);
    }
  }
}
</script>

<style scoped>

</style>
