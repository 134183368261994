import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CompanyView from '../views/CompanyView.vue'
import YodaforexView from "@/views/YodaforexView";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/companies/:companyUuid',
		name: 'CompanyView',
		component: CompanyView,
		props: true,
	},
	{
		path: '/for-yodaforex',
		name: 'YodaforexView',
		component: YodaforexView,
		props: true,
		meta: {
			isPublic: true,
			hideAppBar: true
		}
	},
	{
		path: '/companies/:companyUuid/branches/:branchUuid',
		name: 'BranchView',
		component: CompanyView,
		props: true
	}
]

const router = new VueRouter({
	routes
})

export default router
