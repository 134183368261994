<script>
export default {
  name: "ComputerRoleIcon",
  props: {
    value: {
      type: String,
      required: true
    }
  },
}
</script>

<template>
  <div>

    <v-tooltip top>
      <template v-slot:activator="{on, attrs}">
        <v-icon v-on="on" v-bind="attrs" v-if="value === 'SERVER'" color="blue darken-1">mdi-desktop-tower</v-icon>
        <v-icon v-on="on" v-bind="attrs" v-else-if="value === 'CLIENT'" color="light-blue">mdi-desktop-tower-monitor</v-icon>
        <v-icon v-on="on" v-bind="attrs" v-else-if="value === 'MONO'" color="green darken-3">mdi-desktop-classic</v-icon>
        <v-icon v-on="on" v-bind="attrs" v-else-if="value === 'PILOT'" color="purple darken-1">mdi-laptop</v-icon>
        <v-icon v-on="on" v-bind="attrs" v-else color="blue-grey">mdi-desktop-classic</v-icon>
      </template>
      {{ value }}
    </v-tooltip>


  </div>

</template>

<style scoped>

</style>
