<template>
  <div>
    <v-tooltip top v-if="value.containsComputersToUpdate">
      <template v-slot:activator="{on, attrs}">
        <v-icon v-on="on" v-bind="attrs" class="ml-1" small color="warning">mdi-update</v-icon>
      </template>
      Une ou plusieurs machines doivent mettre à jour leur système d'exploitation
    </v-tooltip>

    <v-tooltip top v-if="value.containsObsoleteComputers">
      <template v-slot:activator="{on, attrs}">
        <v-icon v-on="on" v-bind="attrs" class="ml-1" small color="error">mdi-alert</v-icon>
      </template>
      Une ou plusieurs machines sont obsolètes et doivent être remplacées
    </v-tooltip>

    <v-tooltip top v-if="value.containsComputersWithStorageWarning">
      <template v-slot:activator="{on, attrs}">
        <v-icon v-on="on" v-bind="attrs" class="ml-1" small color="warning">mdi-harddisk</v-icon>
      </template>
      Une ou plusieurs machines ont un espace disque faible
    </v-tooltip>

    <v-tooltip top v-if="value.containsComputersWithStorageAlert">
      <template v-slot:activator="{on, attrs}">
        <v-icon v-on="on" v-bind="attrs" class="ml-1" small color="error">mdi-harddisk</v-icon>
      </template>
      Une ou plusieurs machines ont un espace disque critique
    </v-tooltip>

  </div>
</template>

<script>
export default {
  name: "StatusIcons",
  props: ['value']
}
</script>

<style scoped>

</style>
