<template>
  <v-container :fluid="displayMode === 1">
    <v-snackbar color="error" v-model="error">Une erreur est survenue...</v-snackbar>

    <div class="d-flex flex-column mb-4">
      <div v-if="!isSoloCompany" class="d-flex">
        <v-spacer/>
        <v-btn text small @click="downloadAllAsCSV" :loading="csv.loading">
          <v-icon left>mdi-download</v-icon>
          Tout en CSV
        </v-btn>
        <v-snackbar v-if="csv.error" :timeout="5" color="error" top>Une erreur est survenue lors du téléchargement du fichier CSV</v-snackbar>
      </div>

      <v-select v-model="filter" :items="availableFilters" label="Éléments à afficher"/>

      <div v-if="!isSoloCompany" class="d-flex flex-row align-center">
        <div class="d-flex flex-column align-center pr-2">
        <span class="caption">Filtrer par</span>
        <v-btn-toggle tile mandatory dense v-model="filterMode">
          <v-btn x-small>Agence</v-btn>
          <v-btn x-small>Société</v-btn>
        </v-btn-toggle>
        </div>

        <y-branch-select v-if="filterMode === 0" v-model="branchToFilterOn"/>
        <y-company-select v-else v-model="companyToFilterOn"/>

      </div>

    </div>


    <div class="d-flex flex-row align-center mt-10">
      <v-spacer/>
      <!-- Choix du mode d'affichage -->
      <v-btn-toggle mandatory dense tile v-model="displayMode">
        <v-btn small><v-icon>list</v-icon></v-btn>
        <v-btn small><v-icon small>mdi-grid</v-icon></v-btn>
      </v-btn-toggle>
    </div>
    <v-card>
      <computers-as-tree v-if="displayMode === 0" :companies="companies" :filter="filter" :loading="treeLoading" :branch-to-filter-on="branchToFilterOn" :company-to-filter-on="companyToFilterOn"/>
      <computers-as-grid v-else-if="displayMode === 1" :computers="computers" :filter="filter" :loading="gridLoading" :branch-to-filter-on="branchToFilterOn" :company-to-filter-on="companyToFilterOn"/>
    </v-card>

  </v-container>
</template>

<script>

import {mapState} from "vuex";
import ComputersAsTree from "@/components/ComputersAsTree.vue";
import ComputersAsGrid from "@/components/ComputersAsGrid.vue";

export default {
  name: 'Home',
  components: {ComputersAsGrid, ComputersAsTree},
  data: () => ({
    displayMode: -1, // Une valeur par défaut sera donnée dans mounted()
    treeLoading: false,
    gridLoading: false,
    error: false,
    companies: [],
    computers: [],
    filter: 'all',
    branchToFilterOn: null,
    companyToFilterOn: null,
    availableFilters: [
      {text: "Tout afficher", value: 'all'},
      {text: "Système d'exploitation à mettre à jour", value: "to-update"},
      {text: "Machines obsolète", value: "obsolete"},
      {text: "Espace disque faible", value: "storage-warning"},
      {text: "Espace disque critique", value: "storage-alert"}],
    filterMode: 0,
    csv: {
      loading: false,
      error: false,
    }
  }),
  mounted() {
    let previousDisplayStr = window.localStorage.getItem('yfx-genepi.prefs.display-mode');
    if (previousDisplayStr && previousDisplayStr >= 0 && previousDisplayStr <= 1) {
      this.displayMode = Number.parseInt(previousDisplayStr);
    } else {
      this.displayMode = 0; // On met à jour dans tous les cas pour déclencher le watcher
    }
  },
  watch: {
    filterMode() {
      this.branchToFilterOn = null;
      this.companyToFilterOn = null;
    },
    displayMode(v) {
      window.localStorage.setItem('yfx-genepi.prefs.display-mode', v);
      if (v === 0 && this.companies.length === 0) {
        this.fetchComputersAsTree();
      } else if (v === 1 && this.computers.length === 0) {
        this.fetchComputersAsGrid();
      }
    }
  },
  computed: {
    ...mapState('auth', ['userAuthorisations']),
    isSoloCompany() { // Indique si l'utilisateur n'a les droits que pour 1 seul société
      if (this.userAuthorisations.filter(ua=>ua.authorization.role === 'root').length >= 1) return false; // L'utilisateur a les droits root
      if (this.userAuthorisations.filter(ua=>ua.authorization.role === 'readonly' && ua.scope.companyUuid).length >= 1) return true; // L'utilisateur a des droits pour 1 société
      else return false;
    },
  },
  methods: {
    fetchComputersAsTree() {
      this.treeLoading = true;
      this.error = false;
      this.$http.get(this.$config.apiUrl + '/v1/computers?asTree')
          .then((r) => this.companies = r.data)
          .catch(() => this.error = true)
          .finally(() => this.treeLoading = false);
    },
    fetchComputersAsGrid() {
      this.gridLoading = true;
      this.error = false;
      this.$http.get(this.$config.apiUrl + '/v1/computers?withAllData')
          .then((r) => this.computers = r.data)
          .catch(() => this.error = true)
          .finally(() => this.gridLoading = false);
    },
    downloadAllAsCSV() {
      this.csv.loading = true;
      this.csv.error = false;
      this.$http.get(this.$config.apiUrl + '/v1/computers?asCSV')
          .then((r) => {
            let blob = new Blob([r.data], {type: 'text/csv'});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'all-computers.csv';
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => this.csv.error = true)
          .finally(() => this.csv.loading = false);
    }
  }
}
</script>
