var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.value.containsComputersToUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-update")])]}}],null,false,1024560198)},[_vm._v(" Une ou plusieurs machines doivent mettre à jour leur système d'exploitation ")]):_vm._e(),(_vm.value.containsObsoleteComputers)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert")])]}}],null,false,1752239147)},[_vm._v(" Une ou plusieurs machines sont obsolètes et doivent être remplacées ")]):_vm._e(),(_vm.value.containsComputersWithStorageWarning)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-harddisk")])]}}],null,false,1235412189)},[_vm._v(" Une ou plusieurs machines ont un espace disque faible ")]):_vm._e(),(_vm.value.containsComputersWithStorageAlert)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-harddisk")])]}}],null,false,355415439)},[_vm._v(" Une ou plusieurs machines ont un espace disque critique ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }