<template>
  <span>
    <span class="mr-2" v-for="(count, os) of osCounter" :key="os">
      <os-icon :os="os"/><span>x{{ count }}</span>
    </span>
  </span>
</template>

<script>
import OsIcon from "@/components/OsIcon";
export default {
  name: "OsCountLabel",
  components: {OsIcon},
  props: ['osCounter']
}
</script>

<style scoped>

</style>
