<template>
  <v-container>
    <v-snackbar color="error" v-model="error">Une erreur est survenue</v-snackbar>
    <v-card :loading="loading">
      <v-card-title>
        <y-company-label :value="companyUuid" :copy-on-click="false" :show-tooltip-only-on-error="true"/>
      </v-card-title>
      <v-list v-if="!loading && !error">
        <div v-if="company != null">
          <branch-list-item v-bind:class="{'teal accent-2': b.uuid === $route.params['branchUuid']}"
                            :is-sub-group="false" :branch="b" v-for="(b,i) in company.branches" :key="i"
                            :company="company" :opened-by-default="b | shouldBeOpened($route)"/>
        </div>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-subtitle>Aucun élément à afficher</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import BranchListItem from "@/components/BranchListItem";

export default {
  name: "CompanyView",
  components: {BranchListItem},
  props: ['companyUuid', 'branchUuid'],
  data: () => ({
    loading: false,
    error: false,
    company: null
  }),
  filters: {
    shouldBeOpened(b, route) {
      let branchUuid = route.params['branchUuid'];
      if (branchUuid) {
        return b.uuid === branchUuid;
      } else return true;
    }
  },
  watch: {
    companyUuid(v) {
      this.fetch(v);
    }
  },
  mounted() {
    this.fetch(this.companyUuid);
  },
  methods: {
    fetch(companyUuid) {
      this.loading = true;
      this.$http.get(this.$config.apiUrl + "/v1/companies/" + companyUuid + "/computers?asTree")
          .then(r => this.company = r.data)
          .catch(() => this.error = true).finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
