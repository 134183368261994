import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Config from './config'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import store from './store'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import YfxCloudAuth from '@yodatech/vue-yfx-cloud-auth'
import YfxLicense from '@yodatech/vue-yfx-license'
import './plugins/vue-moment'
import VueTimers from "vue-timers"

Vue.use(VueTimers)
Vue.use(YfxCloudAuth, {serviceName: "yfx-computer-park", store: store, axios: axios, ...Config.authConfig})
Vue.use(YfxLicense, {axios: axios})

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$config = Config

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App)
}).$mount('#app')
