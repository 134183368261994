<script>
  import convertBytesToHumanReadable from "human-readable-bytes";

  export default {
    name: "ComputerStorageStatus",
    props: ['computer'],
    filters: {
      colorForDiskAvailable(v) {
        if (v === 'OK') return 'success';
        if (v === 'WARNING') return 'warning';
        if (v === 'ALERT') return 'error';
        if (v === 'UNKNOWN') return 'secondary';
      },
      humanReadableBytes(v, method = 1024) {
        return convertBytesToHumanReadable(v, method, 0, false)
      },
    }

  }
</script>

<template>
  <v-tooltip left>
    <template v-slot:activator="{on, attrs}">
      <div style="position: relative">
        <v-icon small style="position: absolute; top: 25%; left: .5em">mdi-harddisk</v-icon>
        <v-progress-circular v-on="on" v-bind="attrs" :value="100 - (computer.parsedData.hardware.mainStorage.available / computer.parsedData.hardware.mainStorage.capacity * 100)" :color="computer.dynamicComputerData.storageStatus | colorForDiskAvailable"/>
      </div>
    </template>
    <div class="d-flex flex-column">
      <span class="font-weight-medium">Espace disque</span>
      <span>Total : {{computer.parsedData.hardware.mainStorage.capacity | humanReadableBytes(1000)}}</span>
      <span>Disponible : {{computer.parsedData.hardware.mainStorage.available | humanReadableBytes(1000)}}</span>
    </div>
  </v-tooltip>
</template>

<style scoped>

</style>
