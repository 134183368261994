import { render, staticRenderFns } from "./ComputerRoleIcon.vue?vue&type=template&id=a0faf8fa&scoped=true&"
import script from "./ComputerRoleIcon.vue?vue&type=script&lang=js&"
export * from "./ComputerRoleIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0faf8fa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
